import React, { Component, Suspense } from 'react'
import { HashRouter, BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import { PublicRoute } from './router/PublicRoute'
import { PrivateRoute } from './router/PrivateRoute'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
        <AuthProvider>
          <BrowserRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route
                  path="public/*"
                  element={
                    <PublicRoute>
                      <Routes>
                        <Route exact path="/login" name="Login Page" element={<Login />} />
                        <Route exact path="/404" name="Page 404" element={<Page404 />} />
                        <Route exact path="/500" name="Page 500" element={<Page500 />} />
                      </Routes>
                    </PublicRoute>
                  }
                ></Route>

                <Route
                  path="/*"
                  element={
                    <PrivateRoute>
                      <Routes>
                        <Route path="*" name="Home" element={<DefaultLayout />} />
                      </Routes>
                    </PrivateRoute>
                  }
                ></Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
        </AuthProvider>
      </LocalizationProvider>
    )
  }
}

export default App
