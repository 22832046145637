import { createTheme } from '@mui/material/styles'
import { DataGrid, esES } from '@mui/x-data-grid'
const theme = createTheme(
  {
    typography: {
      fontFamily: ['system-ui', 'Open Sans'].join(','),
    },
  },
  esES,
)

export default theme
